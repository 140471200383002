<style lang="scss">
.question-robot {
  @apply absolute;
  width: 260px;
  height: 312px;
  transform: scale(0.5);
  top: -78px;
  left: calc(50% - 130px);

  &__container {
    @apply relative h-full;
    transform: translate(10px, 0%);
    animation: emergence1 3s ease-in-out;
  }

  &__part {
    @apply absolute;

    &--body-bottom {
      top: 0px;
      left: 60px;
      animation: body-bottom-move 6s ease-in-out infinite alternate;
    }

    &--body-middle {
      z-index: 3;
      top: 108px;
      left: 102px;
      animation: body-middle-move 5s ease-in-out infinite alternate;
    }

    &--wire {
      z-index: 5;
      right: 24px;
      bottom: 80px;
    }

    &--body-top {
      z-index: 4;
      top: 107px;
      left: 4px;
      animation: body-top-move 5s ease-in-out infinite alternate;
    }

    &--gear-left {
      z-index: 5;
      right: 6px;
      animation: left-gear-move 5s ease-in-out infinite alternate;
    }

    &--gear-right {
      left: 9px;
      animation: right-gear-move 5s ease-in-out infinite alternate;
    }

    &--head {
      z-index: 5;
      bottom: 20px;
      left: 36px;

      animation: head-move 5s ease-in-out 1s infinite alternate;
    }
  }
}

@screen lg {
  .question-robot {
    left: 0;
    top: 0;
    transform: none;
  }
}

@keyframes emergence1 {
  0% {
    transform: translate(-20px, -100%);
  }

  25% {
    transform: translate(10px, -50%);
  }

  50% {
    transform: translate(10px, -50%);
  }

  75% {
    transform: translate(10px, -50%);
  }

  100% {
    transform: translate(10px, 0%);
  }
}

@keyframes body-top-move {
  0% {
    transform: translate(-1px, 2px);
  }

  50% {
    transform: translate(2px, 0px);
  }

  100% {
    transform: translate(1px, 2px) rotate(3deg);
  }
}

@keyframes body-wire-move {
  0% {
    transform: translate(1px, -1px);
  }

  50% {
    transform: translate(1px, 1px);
  }

  100% {
    transform: translate(1px, 1px) rotate(-2deg);
  }
}

@keyframes body-middle-move {
  0% {
    transform: translate(1px, -2px);
  }

  50% {
    transform: translate(-1px, -1px);
  }

  100% {
    transform: translate(1px, 0px) rotate(-3deg);
  }
}

@keyframes body-bottom-move {
  0% {
    transform: translate(0px, -2px);
  }

  50% {
    transform: translate(0px, -1px) rotate(3deg);
  }

  100% {
    transform: translate(-2px, 0px);
  }
}

@keyframes left-gear-move {
  0% {
    transform: translate(0, 0);
  }

  50% {
    transform: translate(1px, 1px) rotate(-3deg);
  }

  100% {
    transform: translate(0px, 0px) rotate(3deg);
  }
}

@keyframes left-gear-move {
  0% {
    transform: translate(0, 0);
  }

  50% {
    transform: translate(1px, 1px) rotate(-3deg);
  }

  100% {
    transform: translate(0px, 0px) rotate(3deg);
  }
}

@keyframes right-gear-move {
  0% {
    transform: translate(0, 0);
  }

  50% {
    transform: translate(-2px, 2px) rotate(4deg);
  }

  100% {
    transform: translate(2px, -2px) rotate(-2deg);
  }
}

@keyframes head-move {
  0% {
    transform: translate(-1px, 1px) rotate(0deg);
  }

  25% {
    transform: translate(1px, -1px) rotate(-15deg);
  }

  50% {
    transform: translate(0px, 1px) rotate(-15deg);
  }

  75% {
    transform: translate(1px, 0px) rotate(15deg);
  }

  100% {
    transform: translate(0px, 0px) rotate(15deg);
  }
}
</style>

<template>
  <div class="question-robot">
    <div class="question-robot__container">
      <BodyBottom
        class="question-robot__part question-robot__part--body-bottom"
      />
      <BodyMiddle
        class="question-robot__part question-robot__part--body-middle"
      />
      <Wire class="question-robot__part question-robot__part--wire" />
      <BodyTop class="question-robot__part question-robot__part--body-top" />
      <RightGear
        class="question-robot__part question-robot__part--gear-right"
      />
      <LeftGear class="question-robot__part question-robot__part--gear-left" />
      <Head class="question-robot__part question-robot__part--head" />
    </div>
  </div>
</template>

<script>
import BodyBottom from '@/components/Icons/questionRobot/BodyBottom.vue';
import BodyMiddle from '@/components/Icons/questionRobot/BodyMiddle.vue';
import BodyTop from '@/components/Icons/questionRobot/BodyTop.vue';
import Wire from '@/components/Icons/questionRobot/Wire.vue';
import RightGear from '@/components/Icons/questionRobot/RightGear.vue';
import LeftGear from '@/components/Icons/questionRobot/LeftGear.vue';
import Head from '@/components/Icons/questionRobot/Head.vue';

export default {
  name: 'QuestionRobot',
  components: {
    BodyBottom,
    BodyMiddle,
    BodyTop,
    Wire,
    RightGear,
    LeftGear,
    Head,
  },
};
</script>
