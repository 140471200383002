<style lang="scss"></style>

<template>
  <div>
    <div
      v-for="(item, idx) in data"
      :key="idx"
      class="actions-block actions-item actions-item--chunked"
      :class="item.color || 'dark-blue'"
    >
      {{ item.text }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'ItemBlockPartial',
  components: {},
  data() {
    return {};
  },
  props: {
    data: { type: Array },
  },
  created() {},
  mounted() {},
  methods: {},
  computed: {},
};
</script>
