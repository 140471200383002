<style lang="scss" scoped>
$replicaText: 520px;

.replica {
  @apply fixed bottom-5 w-full;
  z-index: 11;
  left: 50%;
  max-width: 800px;
  transform: translateX(-50%);

  &__container {
    @apply flex items-center cursor-pointer flex-col md:flex-row mx-auto;
    max-width: 288px;

    @screen md {
      max-width: none;
    }
  }

  &__author {
    @apply -mr-5 ml-7 md:ml-0 -mb-5 md:mb-0;
    z-index: 0;
    align-self: flex-start;
    transform: scale(-1, 1);

    @screen md {
      transform: scale(1, 1);
    }

    &-pic {
      width: 100px;
      height: 100px;

      @screen md {
        width: 200px;
        height: 200px;
        align-self: unset;
      }
    }
  }

  &__body {
    &-name {
      @apply inline-block relative ml-6 md:-mb-9 -mb-6;
      z-index: 2;

      &__text {
        @apply absolute md:top-8 top-5 text-12 md:text-20 font-bold text-white;
        left: 51%;
        transform: translateX(-50%);
      }

      &__icon {
        width: 120px;
        height: 56px;

        @screen md {
          width: 180px;
          height: 84px;
        }
      }
    }

    &-text {
      @apply absolute top-5 md:top-10 left-5 md:left-9 text-14 md:text-20 leading-20 md:leading-28 text-white;
      max-width: 240px;

      @screen md {
        max-width: $replicaText;
      }
    }

    &-content {
      @apply relative;
    }

    &-icon {
      @apply hidden;
      width: 620px;
      height: 168px;

      @screen md {
        @apply block;
      }

      &--mob {
        @apply block;
        width: 288px;
        height: 200px;

        @screen md {
          @apply hidden;
        }
      }
    }
  }
}
</style>

<template>
  <div class="replica">
    <div class="replica__container">
      <div class="replica__author">
        <IconScraper
          class="replica__author-pic"
          v-if="character === 'scraper'"
        />
        <IconPotator
          class="replica__author-pic"
          v-if="character === 'potator'"
        />
      </div>
      <div class="replica__body">
        <div class="replica__body-name">
          <IconReplicaNameBorder class="replica__body-name__icon" />
          <div class="replica__body-name__text">{{ name }}</div>
        </div>
        <div class="replica__body-content">
          <IconReplicaBorderMobile
            class="replica__body-icon replica__body-icon--mob"
          />
          <IconReplicaBorder class="replica__body-icon" />
          <p class="replica__body-text">
            <slot />
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import IconScraper from "@/components/Icons/IconScraper";
import IconPotator from "@/components/Icons/IconPotator";
import IconReplicaBorder from "@/components/Icons/IconReplicaBorder";
import IconReplicaNameBorder from "@/components/Icons/IconReplicaNameBorder";
import IconReplicaBorderMobile from "@/components/Icons/IconReplicaBorderMobile";

export default {
  name: "Replica",
  components: {
    IconScraper,
    IconPotator,
    IconReplicaBorder,
    IconReplicaNameBorder,
    IconReplicaBorderMobile,
  },
  props: {
    character: String,
    name: String,
  },
};
</script>
