<style lang="scss">
.item-question {
  @apply flex flex-col items-center justify-center bg-no-repeat py-3 md:pb-4 md:py-6 flex-shrink-0;
  border-radius: 6px;
  width: 612px;
  box-shadow: 0 4px 0 0 #0073c7;
  background-image: url('/images/question/part-right-1.svg'),
    url('/images/question/part-right-2.svg'),
    url('/images/question/part-left.svg'),
    url('/images/question/part-bottom.svg'), url('/images/question/potator.svg'),
    linear-gradient(90deg, #0094ff 0%, #0062ff 100%);
  background-position: right bottom 110px, 519px 10px, -125px bottom, 
                       right 50px bottom, right 15px center, center;
  
  @media screen and (min-width: 768px) {
    width: 968px;
    background-position: right top 72px, right top, left bottom,
                         right 114px bottom, right 90px center, center;
  }
  &.sm {
    @apply justify-end;
    width: 224px;
    height: 290px;
    background-image: url('/images/question/potator.svg'),
      url('/images/question/part-right-1.svg'),
      linear-gradient(90deg, #0094ff 0%, #0062ff 100%);
    background-position: center top 27px, right top 72px, center;
  }
  & .title {
    @apply font-vtb-demibold uppercase mb-2 px-4 text-center;
    font-size: 16px;
    line-height: 22px;
    color: #ffffff;

    @media screen and (min-width: 768px) {
      font-size: 20px;
      line-height: 32px;
    }
  }
  & .subtitle {
    @apply font-vtb-light mb-4 text-center;
    font-size: 16px;
    line-height: 28px;
    color: #ffffff;
    max-width: 350px;

    @media screen and (min-width: 768px) {
      font-size: 20px;
    }
  }
  & .start-btn {
    @apply px-6 font-vtb-demibold;
    --height: 38px;
    font-size: 14px;
    height: var(--height);
    line-height: var(--height);
    background: #ffc400;
    border-radius: 4px;
    @media screen and (min-width: 768px) {
      --height: 44px;
      font-size: 16px;
    }
    &:hover {
      background: #ffba00;
    }
    &:hover:active {
      background: #f4a900;
    }
    &:focus {
      outline: none;
    }
  }
}
</style>

<template>
  <div class="item-question" :class="type">
    <div class="title">{{ title }}</div>
    <div class="subtitle" v-if="subtitle">{{ subtitle }}</div>
    <button @click="$emit('start')" class="start-btn">{{ buttonText }}</button>
  </div>
</template>

<script>
import { computed } from '@vue/composition-api';
import useGameState from '@/composables/useGameState';

export default {
  name: 'ItemQuestion',
  components: {},
  data() {
    return {};
  },
  setup() {
    const { stageData, stage } = useGameState();
    const titles = {
      0: 'сначала определи путь пользователя',
      1: 'продумай шаги исследования',
      2: 'соберись с мыслями',
      3: 'ТУТ ТОЖЕ НАДО ПОДУМАТЬ',
      4: 'Не забудь определить цели',
      5: 'какие эмоции ты испытываешь?',
      6: 'подумай об опыте',
      7: 'дай рекомендации',
      8: 'Ты открыл CJM. Остался последний вопрос',
    };
    const subtitles = {
      0: '',
      1: '',
      2: 'И давай определим, о чем идет речь, чтобы правильно составить CJM',
      3: 'Это очень важная составляющая при конструировании CJM',
      4: '',
      5: '',
      6: '',
      7: 'Это ведь самое главное, объяснить, как улучшить продукт',
      8: '',
    };
    const buttonTexts = {
      0: 'Заполнить шаги',
      1: 'Заполнить блок',
      2: 'Заполнить блок',
      3: 'Заполнить блок',
      4: 'Заполнить блок',
      5: 'Заполнить блок',
      6: 'Заполнить блок',
      7: 'Заполнить блок',
      8: 'Ответить',
    };
    const title = computed(() => {
      return titles[stage.value];
    });
    const subtitle = computed(() => {
      return subtitles[stage.value];
    });
    const buttonText = computed(() => {
      return buttonTexts[stage.value];
    });
    return {
      title,
      subtitle,
      buttonText,
    };
  },
  props: {
    type: {
      type: String,
      default: 'md',
      validator: function (value) {
        return ['md', 'sm'].includes(value);
      },
    },
  },
  created() {},
  mounted() {},
  methods: {},
  computed: {},
};
</script>
