<style lang="scss">
.vm--modal.question-modal {
  background: rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(4px);
  max-width: 100%;
}
.question-modal {
  &__content {
    @apply flex items-end justify-end relative px-4 pb-4 lg:px-0 lg:pb-0;
    min-height: 100vh;
  }
}
.question-container {
  width: 100%;
  margin-top: 152px;
  min-height: calc(100vh - 152px);
}
@screen lg {
  .question-container {
    width: 81%;
    margin-right: 32px;
    height: calc(100vh - 132px);
    margin-top: 0;
    min-height: 0;
  }
}

@screen xl {
  .question-container {
    @apply absolute;
    width: 1076px;
    margin-right: 32px;
    height: 516px;
    left: calc(60% - 538px);
    top: 50%;
    transform: translateY(-50%);
  }
}
</style>

<template>
  <modal
    :name="modalName"
    classes="question-modal"
    clickToClose
    height="auto"
    width="100%"
    scrollable
    @before-open="beforeOpen"
    @before-close="beforeClose"
  >
    <div class="question-modal__content">
      <QuestionRobot />
      <game-question class="question-container" />
    </div>
  </modal>
</template>

<script>
import { MODAL_NAMES } from '@/consts.js';
import GameQuestion from '../Game/GameQuestion.vue';
import QuestionRobot from '@/components/Game/QuestionRobot.vue';
import useGameQuestions from '@/composables/useGameQuestions';
import useGameState from '@/composables/useGameState';

export default {
  name: 'QuestionModal',
  components: { GameQuestion, QuestionRobot },
  data() {
    return {
      modalName: MODAL_NAMES.QUESTION,
    };
  },
  props: {},
  setup() {
    const { isLoading, error, question, getQuestion } = useGameQuestions();
    const { updateGameState } = useGameState();
    return {
      isLoading,
      error,
      question,
      getQuestion,
      updateGameState,
    };
  },
  created() {},
  mounted() {},
  methods: {
    beforeOpen() {
      this.getQuestion();
    },
    beforeClose() {
      this.updateGameState();
    },
  },
  computed: {},
  watch: {
    error: function(val) {
      if (val) {
        this.$notify({
          type: "error",
          text: "Произошла ошибка. Перезагрузите страницу",
        });
      }
    }
  }
};
</script>
