<style lang="scss">
.vm--modal.end-modal {
  background-image: linear-gradient(
    180deg,
    rgba(0, 163, 255, 0.25) 0%,
    rgba(247, 248, 255, 0) 100%
  );
  background-color: rgba(255, 255, 255, 0.75);
}
.end-modal {
  &__content {
    height: 100vh;
    width: 100%;
    margin-top: 185px;

    @screen md {
      margin-top: 0;
    }
  }

  &__button {
    &:not(:last-child) {
      margin-bottom: 14px;

      @screen md {
        margin-bottom: 0;
      }
    }
  }

  &__robot {
  }
}
</style>

<template>
  <div class="end-modal__content">
    <QuestionRobot class="end-modal__robot" />
    <result-block
      :supertitle="`${resultValue}% правильных ответов`"
      title="Поздравляю!"
      :subtitle="resultText"
    >
      <button class="end-modal__button l-g-btn md:mr-4" @click="goToResult">
        В магазин тортиков!
      </button>
      <button
        class="end-modal__button l-g-btn l-g-btn--outline"
        @click="showCjm"
      >
        Посмотреть карту
      </button>
    </result-block>
  </div>
</template>

<script>
import QuestionRobot from '@/components/Game/QuestionRobot.vue';
import ResultBlock from '../UI/ResultBlock.vue';
import { MODAL_NAMES } from '@/consts.js';

export default {
  name: 'EndOfGameModal',
  components: { ResultBlock, QuestionRobot },
  data() {
    return {};
  },
  props: {
    resultValue: { type: Number },
  },
  created() {},
  mounted() {},
  methods: {
    goToResult() {
      this.$router.push({ name: 'Results' });
      this.$modal.hide(MODAL_NAMES.ENDOFGAME);
    },
    showCjm() {
      this.$modal.hide(MODAL_NAMES.ENDOFGAME);
    },
  },
  computed: {
    resultText() {
      if (this.resultValue <= 44) {
        return 'Пусть ты не совсем эксперт в построении CJM, но благодаря любопытству и логике все же помог нам. Теперь наш магазин готов и тортики уже ждут покупателей!';
      } else if (this.resultValue <= 66) {
        return 'Для новичка ты довольно неплохо справился с построением карты пути пользователя. Теперь наш магазин готов и тортики уже ждут покупателей!';
      } else {
        return 'Ты отличный специалист и мастерски строишь карты пути пользователя. Признаться честно, без тебя мы бы не справились. Теперь наш магазин готов и тортики уже ждут покупателей!';
      }
    },
  },
};
</script>
