<style lang="scss"></style>

<template>
  <img class="actions-block__image mx-auto" :src="data" alt="" />
</template>

<script>
export default {
  name: 'ItemEmoji',
  components: {},
  data() {
    return {};
  },
  props: {
    data: { type: String },
  },
  created() {},
  mounted() {},
  methods: {},
  computed: {},
};
</script>
