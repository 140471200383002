<style lang="scss">
.cjm-page {
  min-height: 100vh;
  background-image: url('/images/сjm-bg.png');
  background-color: #f6f8ff;
}
.page-loader {
  @apply fixed z-10;
  top: calc(50% - 40px);
  left: calc(50% - 40px);
}
</style>

<template>
  <div class="cjm-page">
    <question-modal />
    <DialogModal :currentDialog="currentDialog" :onDialogEnd="onDialogEnd" />
    <transition-fade>
      <Loader v-if="isFirstLoading" class="page-loader" />
      <div v-else>
        <game-header is-fixed class="px-4 lg:px-8 z-20" />
        <main-c-j-m />
      </div>
    </transition-fade>
  </div>
</template>

<script>
import MainCJM from '@/components/CJM/MainCJM.vue';
import GameHeader from '@/components/GameHeader.vue';
import QuestionModal from '../components/modals/QuestionModal.vue';
import useGameState from '@/composables/useGameState';
import DialogModal from '@/components/modals/DialogModal.vue';
import Loader from '@/components/Loader.vue';
import { computed, ref, watch } from '@vue/composition-api';
import dialog0 from '@/assets/text/dialogs/dialog-0.json';
import dialog3 from '@/assets/text/dialogs/dialog-3.json';
import dialog7 from '@/assets/text/dialogs/dialog-7.json';
import { MODAL_NAMES } from '@/consts.js';
import TransitionFade from '../components/Animation/TransitionFade.vue';

export default {
  name: 'CjmPage',
  components: {
    MainCJM,
    GameHeader,
    QuestionModal,
    DialogModal,
    Loader,
    TransitionFade,
  },
  data() {
    return {
      dialogs: { 0: dialog0, 3: dialog3, 7: dialog7 },
    };
  },
  props: {},
  setup(props, ctx) {
    const {
      isLoading,
      error,
      stageData,
      updateGameState,
      stage,
      showDialog,
      setShowDialog,
      shouldShowDialog,
    } = useGameState();
    const isFirstLoading = ref(true);
    watch(isLoading, (val) => {
      if (!val) {
        isFirstLoading.value = false;
      }
    });
    const answeredQuestions = computed(() => {
      if (stageData.value) {
        return stageData.value.answered_stage_questions;
      }
      return 0;
    });
    const totalQuestions = computed(() => {
      if (stageData.value) {
        return stageData.value.total_stage_questions;
      }
      return 0;
    });

    return {
      isLoading,
      error,
      answeredQuestions,
      totalQuestions,
      updateGameState,
      stage,
      showDialog,
      setShowDialog,
      shouldShowDialog,
      stageData,
      isFirstLoading,
    };
  },
  computed: {
    currentDialog() {
      return this.dialogs[this.stage];
    },
  },
  methods: {
    onDialogEnd() {
      this.setShowDialog();
      this.$modal.hide(MODAL_NAMES.DIALOG);
    },
  },
  created() {
    this.updateGameState();
  },
  watch: {
    stage: function (val) {
      if (this.shouldShowDialog) {
        this.$modal.show(MODAL_NAMES.DIALOG);
      }
    },
    shouldShowDialog: function (val) {
      if (val) {
        this.$modal.show(MODAL_NAMES.DIALOG);
      }
    },
  },
  mounted() {
    if (this.shouldShowDialog) {
      this.$modal.show(MODAL_NAMES.DIALOG);
    }
  },
};
</script>
