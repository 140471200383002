<style lang="scss">
.cjm-drag {
  height: 100%;
  max-width: 100vw;
  overflow: auto;
  padding-top: 80px;
  padding-bottom: 80px;
}
.cjm-wrapper {
  @apply px-4 mt-24;
  width: 100%;
}
.result-btn {
  @apply fixed bottom-8;
  z-index: 10;
  left: calc(50% - 86px);
  box-shadow: 0px 8px 24px -4px rgba(0, 163, 255, 1);
}
@screen md {
  .result-btn {
    left: calc(50% - 98px);
  }
}
.cjm-chunk {
  @apply mb-6;

  &__item {
    &:not(:first-child) {
      margin-left: 172px;

      @screen md {
        margin-left: 180px;
      }
    }
  }
}
</style>

<template>
  <div class="cjm-drag">
    <div class="cjm-wrapper">
      <button
        v-if="showResultButton"
        class="l-g-btn result-btn"
        @click="$router.push({ name: 'Results' })"
      >
        Результаты
      </button>

      <CjmRow
        v-for="(row, idx) in rows"
        :key="idx"
        :items="row.items"
        :partial="row.isPartial"
        :type="row.type"
        :sideText="row.title"
        :sideVisible="row.sideVisible"
        :rowVisible="row.rowVisible"
        :isQuestion="row.isQuestion"
        :stage="stage"
        :leftQuestionContainer="row.leftQuestionContainer"
        :tooltip="row.tooltip"
        @start="openQuestion"
      >
        <template v-slot:item="{ item }">
          <component :is="row.component" v-if="row.rowVisible" :data="item" />
          <item-hidden v-else :type="row.hiddenType" />
        </template>
      </CjmRow>
    </div>
  </div>
</template>

<script>
import { MODAL_NAMES } from '@/consts.js';
import useGameState from '@/composables/useGameState';
import { computed } from '@vue/composition-api';
import EndOfGameModal from '../modals/EndOfGameModal.vue';
import CjmRow from '@/components/CJM/CjmRow.vue';

import ItemTitle from './ItemTitle.vue';
import ItemBlock from './ItemBlock.vue';
import ItemEmoji from './ItemEmoji.vue';

import cjmData from '@/assets/text/cjm.json';
import ItemBlockPartial from './ItemBlockPartial.vue';
import ItemHidden from './ItemHidden.vue';

export default {
  name: 'MainCJM',
  components: {
    CjmRow,
    ItemTitle,
    ItemBlock,
    ItemEmoji,
    ItemBlockPartial,
    ItemHidden,
  },
  data() {
    return {
      showResultButton: false,
      headers: cjmData.headers,
      actions: cjmData.actions,
      contactPoints: cjmData.contactPoints,
      purposes: cjmData.purposes,
      emotions: cjmData.emotions,
      experience: cjmData.experience,
      recommendations: cjmData.recommendations,
    };
  },
  props: {},
  setup() {
    const { stageData, stage } = useGameState();

    const isFinished = computed(() => {
      if (stageData.value) {
        return (
          stageData.value.answered_stage_questions ===
          stageData.value.total_stage_questions
        );
      }
    });
    const resultValue = computed(() => {
      if (stageData.value) {
        return Math.round(
          (stageData.value?.correct / stageData.value?.total_stage_questions) *
            100
        );
      }
    });
    return {
      stage,
      isFinished,
      resultValue,
    };
  },
  created() {},
  mounted() {
    console.log('stage', this.stage);
    if (this.isFinished) {
      this.openEndOfGameModal();
    }
  },
  methods: {
    openQuestion() {
      this.$modal.show(MODAL_NAMES.QUESTION);
    },
    openEndOfGameModal() {
      this.$modal.show(
        EndOfGameModal,
        {
          resultValue: this.resultValue,
        },
        {
          name: MODAL_NAMES.ENDOFGAME,
          classes: 'end-modal',
          height: 'auto',
          width: '100%',
          clickToClose: false,
          scrollable: true,
        },
        {
          'before-open': () => {},
          'before-close': () => {
            this.showResultButton = true;
          },
        }
      );
    },
    scrollToBlinkButton() {
      //const el = this.$refs.blinkButton.$el;
      /* if (el) {
        el.scrollIntoView({ behavior: 'smooth' });
      } */
    },
  },
  computed: {
    rows() {
      return [
        {
          items: this.headers,
          component: 'item-title',
          type: '',
          isPartial: false,
          title: '',
          rowVisible: true,
          isQuestion: this.stage === 0,
          sideVisible: false,
        },
        {
          items: this.actions,
          component: 'item-block',
          type: '',
          isPartial: false,
          title: 'действия',
          rowVisible: this.stage > 2,
          hiddenType: 'xl',
          isQuestion: this.stage === 2,
          sideVisible: this.stage > 1,
          leftQuestionContainer: this.stage === 1,
          tooltip: 'Нам в любом случае придется что-то сделать, чтобы клиент совершил целевое действие? В этих вкладках мы описываем варианты подобных активностей.'
        },
        {
          items: this.contactPoints,
          component: 'item-block',
          type: '',
          isPartial: false,
          title: 'точки контакта',
          rowVisible: this.stage > 3,
          isQuestion: this.stage === 3,
          hiddenType: 'sm',
          sideVisible: this.stage > 1,
          tooltip: 'Ваш клиент очень похож на гордых Орлов. Конечно, они живут не в горах, а многоэтажках, но, тем не менее, найти с ними точки соприкосновения бывает нелегко. Именно поэтому нам важно сразу же определить места пребывания потенциального клиента.'
        },
        {
          items: this.purposes,
          component: 'item-block',
          type: '',
          isPartial: false,
          title: 'цели и ожидания',
          rowVisible: this.stage > 4,
          isQuestion: this.stage === 4,
          sideVisible: this.stage > 1,
          tooltip: 'Какова наша цель и чего мы ожидаем от клиента? Без ответа на эти вопросы невозможно выстроить эффективную стратегию коммуникации. Давайте вместе определимся с тем, чего мы хотим и как этого достигнем.'
        },
        {
          items: this.emotions,
          component: 'item-emoji',
          type: 'empty',
          isPartial: false,
          title: 'эмоции',
          rowVisible: this.stage > 5,
          isQuestion: this.stage === 5,
          hiddenType: 'rounded',
          sideVisible: this.stage > 1,
          tooltip: 'Какую эмоцию мы хотим вызывать нашим сервисом или товаром? На что эта эмоция должна повлиять? Ответим на эти вопросы и проясним ситуацию.'
        },
        {
          items: this.experience,
          component: 'item-block-partial',
          type: 'empty',
          isPartial: true,
          title: 'опыт клиента',
          rowVisible: this.stage > 6,
          isQuestion: this.stage === 6,
          hiddenType: 'chuncked',
          sideVisible: this.stage > 1,
          tooltip: 'Какой именно процесс взаимодействия клиента с сервисом мы хотим выстроить? Разберемся на берегу и отправимся в путь подготовленными.'
        },
        {
          items: [],
          component: 'item-block',
          type: 'empty',
          isPartial: false,
          title: '',
          rowVisible: this.stage > 7,
          isQuestion: this.stage === 8,
          hiddenType: 'chuncked',
          sideVisible: this.stage > 1,
        },
        {
          items: this.recommendations,
          component: 'item-block-partial',
          type: 'empty',
          isPartial: true,
          title: 'рекомендации',
          rowVisible: this.stage > 6,
          isQuestion: this.stage === 7,
          hiddenType: 'chuncked',
          sideVisible: this.stage > 1,
          tooltip: 'Ну и самое важное - а как получить обратную связь от уже сконвертированных в покупателя, клиентов? Надо ответить на этот вопрос, чтобы не было проблем.'
        },
      ];
    },
  },
  watch: {
    isFinished: function (val) {
      if (val) {
        this.openEndOfGameModal();
      }
    },
    stage: function () {
      this.scrollToBlinkButton();
    },
  },
  directives: {
    // drag: {
    //   inserted: function (el) {
    //     // console.log('el', el);
    //     let pos = { top: 0, left: 0, x: 0, y: 0 };
    //     const mouseDownHandler = function (e) {
    //       el.style.cursor = 'grabbing';
    //       pos = {
    //         // The current scroll
    //         left: el.scrollLeft,
    //         top: el.scrollTop,
    //         // Get the current mouse position
    //         x: e.clientX,
    //         y: e.clientY,
    //       };
    //       document.addEventListener('mousemove', mouseMoveHandler);
    //       document.addEventListener('mouseup', mouseUpHandler);
    //     };
    //     const mouseMoveHandler = function (e) {
    //       console.log('mouseMoveHandler');
    //       // How far the mouse has been moved
    //       const dx = e.clientX - pos.x;
    //       const dy = e.clientY - pos.y;
    //       console.log(pos.top, dy);
    //       console.log(pos.left, dx);
    //       // Scroll the element
    //       el.scrollTop = pos.top - dy;
    //       el.scrollLeft = pos.left - dx;
    //     };
    //     const mouseUpHandler = function () {
    //       el.style.cursor = 'grab';
    //       document.removeEventListener('mousemove', mouseMoveHandler);
    //       document.removeEventListener('mouseup', mouseUpHandler);
    //     };
    //     document.addEventListener('mousedown', mouseDownHandler);
    //   },
    // },
  },
};
</script>
