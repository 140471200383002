<style lang="scss">
.item-hidden {
  @apply w-full;
  background: rgba(255, 255, 255, 0.25);
  border: 1px dashed #0a2973;
  border-radius: 8px;
  & .chunk {
    display: none;
  }
  &.sm {
    height: 48px;
  }
  &.md {
    height: 64px;
  }
  &.xl {
    height: 224px;
  }
  &.rounded {
    @apply rounded-full m-auto;
    width: 64px;
    height: 64px;
  }
  &.chuncked {
    background: transparent;
    border: none;
    box-sizing: border-box;
    border-radius: 0;
    height: auto;
    & .chunk {
      @apply block mb-4;
      background: rgba(255, 255, 255, 0.25);
      border: 1px dashed #0a2973;
      border-radius: 8px;
      height: 64px;
      &:last-child {
        @apply mb-0;
      }
    }
  }
}
</style>

<template>
  <div class="item-hidden" :class="type">
    <div v-for="i in 3" :key="i" class="chunk"></div>
  </div>
</template>

<script>
export default {
  name: 'ItemHidden',
  components: {},
  data() {
    return {};
  },
  props: {
    type: {
      type: String,
      validator: function (value) {
        return ['xl', 'md', 'sm', 'rounded', 'chuncked'].includes(value);
      },
      default: 'md',
    },
  },
  created() {},
  mounted() {},
  methods: {},
  computed: {},
};
</script>
