import { ref, reactive, toRefs, computed, watch } from '@vue/composition-api'
import { fetchQuestion } from '../api/fetchQuestion'
import { sendAnswer } from '../api/sendAnswer'

const question = ref({})
const answerId = ref(null)
const questionResponse = ref(null)
const state = reactive({
  isLoading: false,
  error: null,
});

export default function useGameQuestions() {
  const getQuestion = async () => {
    state.isLoading = true
    question.value = {}
    answerId.value = null
    state.error = null;
    questionResponse.value = null
    try {
      const result = await fetchQuestion()
      question.value = result
    } catch (e) {
      state.error = e;
    } finally {
      state.isLoading = false;
    }
  }

  const answerQuestion = async (answerId) => {
    state.isLoading = true
    try {
      const result = await sendAnswer(question.value.id, answerId)
      questionResponse.value = result
    } catch (e) {
      state.error = e;
    } finally {
      state.isLoading = false;
    }
  }

  const setAnswerId = (id) => {
    answerId.value = id
  }

  const rightAnswerId = computed(() => {
    return questionResponse.value?.right_answer_id
  });

  const rightAnswer = computed(() => {
    if (!rightAnswerId.value) {
      return {}
    }
    return question.value.answers.find(item => item.id === rightAnswerId.value)
  });

  const answer = computed(() => {
    if (!answerId.value) {
      return {}
    }
    return question.value.answers.find(item => item.id === answerId.value)
  });

  watch(answerId, (val) => { if (val) { answerQuestion(val) } });

  watch(rightAnswerId, (val) => {
    if (val && val !== answerId.value) {
      window.navigator.vibrate(300);
    }
  })

  return {
    ...toRefs(state),
    question,
    getQuestion,
    answerQuestion,
    setAnswerId,
    rightAnswerId,
    rightAnswer,
    answerId,
    answer,
  }
}