<style>
.dialog {
  @apply cursor-pointer;
}
</style>

<template>
  <div class="dialog" @click="nextDialog">
    <Replica :character="currentReplica.id" :name="npc[currentReplica.id].name">
      {{ currentReplica.text }}
    </Replica>
  </div>
</template>

<script>
import Replica from '@/components/Game/Dialog/Replica.vue';

export default {
  name: 'DialogMain',
  components: { Replica },
  props: {
    currentDialog: Array,
  },
  data() {
    return {
      dialogPoint: 0,
      dialogInterval: 7000,
      npc: {
        scraper: {
          name: 'Железяка',
        },
        potator: {
          name: 'Картофелина',
        },
      },
      timeout: null,
      tiker: null,
    };
  },
  computed: {
    currentReplica() {
      return this.currentDialog[this.dialogPoint];
    },
  },
  methods: {
    createTimeout() {
      this.timeout = setTimeout(() => {
        this.nextDialog();
      }, this.dialogInterval);
    },
    nextDialog() {
      clearTimeout(this.timeout);
      this.timeout = null
      if (this.dialogPoint < this.currentDialog.length - 1) {
        this.dialogPoint++;
        this.createTimeout();
      } else {
        this.$emit('onDialogEnd');
      }
    },
  },
  created() {
    this.createTimeout();
  },
  mounted() {
    document.addEventListener('keydown', (event) => {
      if (event.code == 'Enter' || event.code == 'Space') {
        this.nextDialog()
      }
    })
  },
};
</script>
