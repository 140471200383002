var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"game-question"},[_c('div',{staticClass:"game-question__issue"},[_c('div',{staticClass:"game-question__issue-title"},[_vm._v(_vm._s(_vm.quantityTitle))]),_c('div',{staticClass:"game-question__issue-content"},[_c('div',{staticClass:"game-question__issue-text",domProps:{"innerHTML":_vm._s(_vm.questionTextProcessed)}}),_c('transition-fade',[(!_vm.isLoading && _vm.answerId)?_c('div',{staticClass:"game-question__reply"},[_c('div',{staticClass:"game-question__reply-title",class:{
              correct: !_vm.isLoading && _vm.rightAnswerId === _vm.answerId,
              wrong: !_vm.isLoading && _vm.rightAnswerId !== _vm.answerId,
            }},[_vm._v(" "+_vm._s(_vm.answer.text)+" ")]),_c('div',{staticClass:"game-question__reply-text"},[_vm._v(" "+_vm._s(_vm.answer.reply_text)+" ")])]):_vm._e()])],1)]),_c('div',{staticClass:"game-question__answers"},[_c('transition-fade',[(!_vm.answerId)?_c('div',{key:"title-full"},[_vm._v("Выберите вариант ответа")]):_c('div',{key:"title-blank",staticClass:"game-question__answers__title--blank"})]),_c('div',{staticClass:"game-question__answers-container"},_vm._l((_vm.answersProcessed),function(item,idx){return _c('button',{key:item.id,staticClass:"game-question__answers-item list-item",class:{
          correct: !_vm.isLoading && item.id === _vm.rightAnswerId,
          wrong:
            !_vm.isLoading && item.id === _vm.answerId && _vm.answerId !== _vm.rightAnswerId,
          hidden:
            !_vm.isLoading &&
            _vm.answerId &&
            ![_vm.answerId, _vm.rightAnswerId].includes(item.id),
        },style:({ '--idx': ("" + idx) }),attrs:{"disabled":_vm.answerId},on:{"click":function($event){return _vm.setAnswerId(item.id)}}},[_c('div',{staticClass:"game-question__answers-item__idx"},[_vm._v(_vm._s(idx + 1))]),_c('span',{domProps:{"innerHTML":_vm._s(item.text)}})])}),0),_c('transition-fade',[(_vm.answerId)?_c('button',{staticClass:"g-vtb-btn w-full mt-6 relative z-50",on:{"click":_vm.closeModal}},[_vm._v(" Продолжить ")]):_vm._e()])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }