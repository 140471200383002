<style lang="scss">
.item-title {
  @apply font-vtb-demibold uppercase text-center;
  font-size: 8px;
  line-height: 32px;

  @media screen and (min-width: 768px) {
    font-size: 14px;
    line-height: 44px;
  }
}
</style>

<template>
  <div class="item-title">
    {{ data }}
  </div>
</template>

<script>
export default {
  name: 'ItemTitle',
  components: {},
  data() {
    return {};
  },
  props: {
    data: { type: String },
  },
  created() {},
  mounted() {},
  methods: {},
  computed: {},
};
</script>
