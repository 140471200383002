<style lang="scss">
.cjm-block {
  @apply relative flex items-start justify-start lg:justify-center ml-20 md:ml-0;

  &--header {
    // Ширина бокового заголовка + его отступ
    // margin-left: 172px;

    @screen md {
      margin-left: 180px;
    }
  }
  & .title {
    &--hidden {
      @apply w-full;
      background: #dde0f1;
      border-radius: 8px;
      height: 28px;
    }
  }
}

.cjm-row-header {
  &__item {
    @apply font-vtb-demibold flex-shrink-0;
    font-size: 8px;
    line-height: 14px;
    min-width: 144px;
    width: max-content;
    margin-top: 10px;
    margin-right: 20px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    text-transform: uppercase;

    @screen md {
      font-size: 14px;
      line-height: 24px;
      margin-right: 32px;
      margin-top: 0;
    }
  }
}

.actions-wrapper {
  @apply grid mb-10;
  grid-template-columns: repeat(4, 135px);
  grid-column-gap: 24px;
  padding-right: 130px;

  @screen md {
    grid-template-columns: repeat(4, 224px);
    padding-right: 0;
  }
}
.cjm-tip {
  @apply ml-4;

  &__icon {
    @apply w-5 h-5;
    color: #3d3d3f;
  }
}
</style>

<template>
  <div class="cjm-block" :class="typeMode ? `cjm-block--${typeMode}` : ''">
    <div class="cjm-row-header__item">
      <item-question
        v-if="leftQuestionContainer"
        class="absolute top-0 left-100 z-10"
        type="sm"
        @start="$emit('start')"
      />
      <div
        v-else-if="sideText"
        class="title flex items-center"
        :class="{ 'title--hidden': !sideVisible }"
      >
        <transition-fade>
          <div v-if="sideVisible">
            {{ sideText }}
          </div>
        </transition-fade>
        <div
          v-if="sideVisible"
          class="cjm-tip"
          :content="tooltip"
          v-tippy="{ placement: 'top' }"
        >
          <IconQuestion class="cjm-tip__icon" />
        </div>
      </div>
    </div>

    <transition-fade>
      <item-question v-if="isQuestion" class="mb-10" @start="$emit('start')" />
      <div
        v-else-if="items.length"
        class="actions-wrapper"
        :class="{ 'actions-wrapper--chunked': partial }"
      >
        <div
          v-for="(item, idx) in items"
          :key="idx"
          class="actions-block"
          :class="{ 'actions-block--empty': type === 'empty' || !rowVisible }"
        >
          <slot name="item" :item="item" />
        </div>
      </div>
    </transition-fade>
  </div>
</template>

<script>
import TransitionFade from '../Animation/TransitionFade.vue';
import ItemQuestion from './ItemQuestion.vue';
import IconQuestion from '@/components/Icons/IconQuestion.vue';

export default {
  components: { TransitionFade, ItemQuestion, IconQuestion },
  name: 'CjmRow',
  data() {
    return {};
  },
  props: {
    items: { type: Array },
    row: Object,
    type: String,
    sideText: { type: String, default: 'define header' },
    sideVisible: { type: Boolean, default: true },
    partial: Boolean,
    rowVisible: { type: Boolean },
    isQuestion: { type: Boolean },
    stage: { type: [Number, String] },
    leftQuestionContainer: { type: Boolean },
    tooltip: { type: String },
  },
  computed: {
    typeMode() {
      if (this.type === 'header') return 'header';
      if (this.type === 'chunked') return 'chunked';
      return '';
    },
  },
};
</script>
