<style lang="scss" scoped></style>

<template>
  <div>
    <div v-for="item in data" :key="item" class="actions-item">
      {{ item }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'ItemBlock',
  components: {},
  data() {
    return {};
  },
  props: {
    data: { type: Array },
    isHidden: { type: Boolean },
  },
  created() {},
  mounted() {},
  methods: {},
  computed: {},
};
</script>
