<style lang="scss">
.vm--overlay {
  background-color: transparent;
}

.vm--modal.dialog-modal {
  background-color: transparent;
}

.dialog-modal {
  &__content {
    @apply h-full;
  }

  &__main {
    @apply h-full;
  }
}
</style>

<template>
  <modal
    :name="modalName"
    classes="dialog-modal"
    :clickToClose="false"
    :scrollable="false"
    height="100%"
    width="100%"
  >
    <div class="dialog-modal__content">
      <DialogMain 
        class="dialog-modal__main"
        :currentDialog="currentDialog"
        @onDialogEnd="onDialogEnd"
      />
    </div>
  </modal>
</template>

<script>
import DialogMain from '@/components/Game/Dialog/DialogMain.vue';
import { MODAL_NAMES } from '@/consts.js';

export default {
  name: 'DialogModal',
  components: { DialogMain },
  props: {
    currentDialog: Array,
    onDialogEnd: Function,
  },
  data() {
    return {
      modalName: MODAL_NAMES.DIALOG,
    }
  }
}
</script>
