import axios from 'axios';

export const sendAnswer = async (questionId, answerId) => {
  const response = await axios.post('/game/questions/answer', {
    question_type: 'common',
    question_id: questionId,
    common_answer_id: answerId
  });
  return response.data;
};
