<style lang="scss">
.game-question {
  @apply flex justify-between font-vtb-regular flex-col lg:flex-row;
  &__issue {
    @apply mb-2 lg:mb-0 flex-grow lg:flex-grow-0;
    width: 100%;
    background: #0a2973;
    border-radius: 8px;
    &-title {
      padding: 14px 24px;
      background: rgba(0, 163, 255, 0.15);
      font-weight: 600;
      font-size: 10px;
      line-height: 16px;
      color: #00a3ff;
    }
    &-content {
      @apply p-6 flex flex-col;
      height: calc(100% - 48px);
    }
    &-text {
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      color: #ffffff;
    }
  }
  &__answers {
    width: 100%;
    background: #0a2973;
    border-radius: 8px;
    padding: 14px 24px 24px 24px;
    font-weight: 600;
    font-size: 10px;
    line-height: 16px;
    color: #ffffff;
    margin-bottom: 32px;
    &__title {
      &--blank {
        height: 16px;
      }
    }
  }
  &__answers-container {
    margin-top: 20px;
  }
  &__answers-item {
    @apply font-vtb-light text-left px-5 py-3 relative;
    min-height: 68px;
    background: #083b88;
    border-radius: 4px;
    font-size: 14px;
    line-height: 20px;
    color: #ffffff;
    width: 100%;
    margin-bottom: 12px;
    padding-left: 40px;
    display: block;
    opacity: 1;
    transition: all 0.5s linear;
    &__idx {
      @apply absolute font-vtb-demibold;
      top: calc(50% - 10px);
      left: 16px;
    }
    &:first-child {
      margin-top: 0;
    }
    &:last-child {
      margin-bottom: 0;
    }
    &.correct {
      border: 2px solid #43a86d;
    }
    &.wrong {
      border: 2px solid #ed4343;
    }
    &:focus {
      outline: none;
    }
    &:disabled {
      @apply cursor-not-allowed;
    }
  }
  &__reply {
    @apply px-5 py-4 rounded-lg flex-grow;
    background: linear-gradient(65deg, #083b88 0%, #0a2973 100%);
    margin-top: 28px;
    overflow: auto;
    &-title {
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      color: #28c96b;
      &.correct {
        color: #43a86d;
      }
      &.wrong {
        color: #ed4343;
      }
    }
    &-text {
      @apply font-vtb-light;
      margin-top: 12px;
      font-size: 14px;
      line-height: 24px;
      color: #ffffff;
    }
  }
}
.hidden {
  opacity: 0;
  min-height: 0;
  height: 0;
  padding: 0;
}
@screen lg {
  .game-question {
    &__issue {
      width: 53%;
      &-text {
        font-size: 20px;
        line-height: 32px;
      }
      &-title {
        font-size: 16px;
        line-height: 24px;
      }
    }
    &__answers-container {
      margin-top: 38px;
    }
    &__answers {
      width: 44%;
      font-size: 16px;
      line-height: 24px;
      &__title {
        &--blank {
          height: 24px;
        }
      }
    }
    &__answers-item {
      font-size: 16px;
    }
    &__reply {
      &-title {
        font-size: 16px;
        line-height: 24px;
      }
      &-text {
        font-size: 16px;
        line-height: 28px;
      }
    }
  }
}
</style>

<template>
  <div class="game-question">
    <div class="game-question__issue">
      <div class="game-question__issue-title">{{ quantityTitle }}</div>
      <div class="game-question__issue-content">
        <div class="game-question__issue-text" v-html="questionTextProcessed" />
        <transition-fade>
          <div v-if="!isLoading && answerId" class="game-question__reply">
            <div
              class="game-question__reply-title"
              :class="{
                correct: !isLoading && rightAnswerId === answerId,
                wrong: !isLoading && rightAnswerId !== answerId,
              }"
            >
              {{ answer.text }}
            </div>
            <div class="game-question__reply-text">
              {{ answer.reply_text }}
            </div>
          </div>
        </transition-fade>
      </div>
    </div>
    <div class="game-question__answers">
      <transition-fade>
        <div v-if="!answerId" key="title-full">Выберите вариант ответа</div>
        <div
          v-else
          key="title-blank"
          class="game-question__answers__title--blank"
        ></div>
      </transition-fade>
      <div class="game-question__answers-container">
        <button
          v-for="(item, idx) in answersProcessed"
          :key="item.id"
          class="game-question__answers-item list-item"
          :class="{
            correct: !isLoading && item.id === rightAnswerId,
            wrong:
              !isLoading && item.id === answerId && answerId !== rightAnswerId,
            hidden:
              !isLoading &&
              answerId &&
              ![answerId, rightAnswerId].includes(item.id),
          }"
          :style="{ '--idx': `${idx}` }"
          :disabled="answerId"
          @click="setAnswerId(item.id)"
        >
          <div class="game-question__answers-item__idx">{{ idx + 1 }}</div>
          <span v-html="item.text" />
        </button>
      </div>
      <transition-fade>
        <button
          v-if="answerId"
          class="g-vtb-btn w-full mt-6 relative z-50"
          @click="closeModal"
        >
          Продолжить
        </button>
      </transition-fade>
    </div>
  </div>
</template>

<script>
import { ref, reactive, toRefs, watch, computed } from '@vue/composition-api';
import { MODAL_NAMES } from '@/consts.js';

import useGameQuestions from '@/composables/useGameQuestions';
import TransitionFade from '../Animation/TransitionFade.vue';

export default {
  name: 'GameQuestion',
  components: { TransitionFade },
  data() {
    return {
      mvpTooltipText:
        'Minimal Viable Product (минимально жизнеспособный продукт) - тестовая версия товара, услуги или сервиса с минимальным набором функций (иногда даже одной), которая несет ценность для конечного потребителя.MVP создают для тестирования гипотез и проверки жизнеспособности задуманного продукта, насколько он будет ценным и востребованным на рынке.',
      cjmTooltipText:
        'Customer Journey Map («карта пути клиента») — визуализация взаимодействия клиента с продуктом. CJM позволяет увидеть все точки контакта и понять, что в них происходит, а затем сформировать идеи, как и где улучшить взаимодействие.',
    };
  },
  props: {},
  setup() {
    const {
      isLoading,
      error,
      question,
      setAnswerId,
      rightAnswerId,
      rightAnswer,
      answerId,
      answer,
    } = useGameQuestions();
    const quantityTitle = computed(() => {
      if (question.value.id) {
        return `Вопрос ${question.value.serial} из ${question.value.total_stage_questions}`;
      }
    });

    return {
      isLoading,
      error,
      question,
      quantityTitle,
      setAnswerId,
      rightAnswerId,
      rightAnswer,
      answerId,
      answer,
    };
  },
  created() {},
  mounted() {},
  methods: {
    closeModal() {
      this.$modal.hide(MODAL_NAMES.QUESTION);
    },
  },
  computed: {
    questionTextProcessed() {
      return this.question.text?.replace(
        'CJM',
        `<div span class="tooltip">CJM
          <span class="tooltiptext">${this.cjmTooltipText}</span>
        </div>`
      );
    },
    answersProcessed() {
      if (!this.question.answers) {
        return [];
      }
      return this.question.answers.map((item) => {
        return {
          ...item,
          text: item.text.replace(
            'MVP',
            `<div span class="tooltip">MVP
          <span class="tooltiptext">${this.mvpTooltipText}</span>
        </div>`
          ),
        };
      });
    },
  },
};
</script>
